<template>
  <div class="convenientService">
    <!-- banner -->
    <div class="convenientService-banner" @click="toPathPage('contactUs')">
      <div class="item-l">联系我们</div>
      <img src="./img/arrow-gray.png" alt="" />
    </div>
    <div class="section">
      <div class="section-l">
        <div
          :class="['nav', { active: currentIndex == index }]"
          v-for="(item, index) in severTypeList"
          :key="index"
          @click="handelSelectServeType(item, index)"
        >
          {{ item.dictValue }}
        </div>
      </div>
      <div class="section-r">
        <div
          class="content"
          v-for="(node, idx) in mergedArray"
          :key="idx"
          ref="contentItem"
        >
          <div class="section-r-title">
            <div class="line"></div>
            <div class="txt">{{ node.dictValue }}</div>
            <div class="line"></div>
          </div>
          <div class="section-r-content" v-if="node.children.length > 0">
            <div
              class="item"
              v-for="(item, index) in node.children"
              :key="index"
              @click="toDetailPage(item)"
            >
              {{ item.dictValue }}
            </div>
          </div>
          <div class="not-data" v-else>
            <v-empty />
          </div>
        </div>
      </div>
    </div>
    <div class="publish-btn" @click="goPublish">
      <img src="./img/public-btn.png" alt="" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
import { mapState } from "vuex";
import { gloabalCount, toRegister } from "@/utils/common";
import {
  getServiceList,
  getOwnServiceInfo,
  getInitInfo,
  getServiceTypeLv2,
} from "./api.js";
import rate from "./rating.vue";
export default {
  name: "convenientService",
  // components: {
  //   rate,
  // },
  props: {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "convenientServiceDetail") {
        if (Object.prototype.hasOwnProperty.call(from.query, "currentIndex")) {
          const { currentIndex } = from.query;
          vm.currentIndex = currentIndex;
        }
      } else if (from.name == "convenientServiceCategories") {
        if (Object.prototype.hasOwnProperty.call(from.query, "currentIndex")) {
          const { currentIndex } = from.query;
          vm.currentIndex = currentIndex;
        }
      }
    });
  },
  data() {
    return {
      requestData: {
        curPage: 1,
        pageSize: 5,
        serviceType: "",
      },
      currentIndex: 0,
      publishList: [], //发布列表
      severTypeList: [],
      ServiceTypeLv2Title: [],
      serviceTypeLv1: "",
      result: [],
      mergedArray: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
    // mergedArray() {
    //   return this.ServiceTypeLv2Title.map((item) => {
    //     const children = this.publishList.filter(
    //       (child) => child.serviceTypeLv2 === item.dictKey
    //     );
    //     if (children.length == 5) {
    //       const params = {
    //         title: "更多",
    //         serviceType: children[0].serviceType,
    //         serviceTypeLv2: children[0].serviceTypeLv2,
    //       };
    //       children.push(params);
    //     }
    //     return {
    //       ...item,
    //       children,
    //     };
    //   });
    // },
  },
  async created() {
    gloabalCount("", 101, 1);
    if (await toRegister(this.$route.path, this.$route.query, "便民服务")) {
      await this.getInitInfo();
    }
  },
  mounted() {},
  methods: {
    //是否展示
    isShow(item) {
      return this.publishList.some(
        (node) => node.serviceTypeLv2 == item.dictKey
      );
    },
    //获取小类
    getServiceTypeLv2(code) {
      // const code = 'convenience_service_type';
      this.$axios.get(`${getServiceTypeLv2}?code=${code}`).then((res) => {
        if (res.code == 200) {
          const { list } = res.data;
          this.result.push(list);
          const args = this.result.flat();
          this.mergedArray = this.severTypeList.map((item) => {
            const children = args.filter(
              (child) => child.code === item.dictKey
            );
            return {
              ...item,
              children,
            };
          });
          this.commonFun(this.severTypeList[this.currentIndex].dictKey);
        }
      });
    },
    //选择大类
    handelSelectServeType(item, index) {
      this.currentIndex = index;
      this.commonFun(item.dictKey);
    },
    commonFun(dictKey) {
      console.log("dictKey----->", dictKey);
      const curNodeIndex = this.mergedArray.findIndex(
        (node) => node.dictKey == dictKey
      );
      if (curNodeIndex > 0) {
        const matchedItem = this.mergedArray.splice(curNodeIndex, 1)[0];
        this.mergedArray.unshift(matchedItem);
      }
    },
    //计算时间差
    checkTime(time) {
      const currentTime = new Date();
      const fixedTime = new Date(time);
      const timeDifference = currentTime - fixedTime;
      const minutesDifference = Math.floor(timeDifference / 60000);
      // 判断当前时间是否在十五分钟前
      if (minutesDifference <= 15 && minutesDifference >= -15) {
        return "刚刚";
      } else {
        // 格式化固定时间
        // const formattedTime = fixedTime.toLocaleString(); // 格式为默认的日期时间格式
        return time;
      }
    },
    //获取大服务类别数据
    getInitInfo() {
      const code = "convenience_service_type";
      this.$axios.get(`${getInitInfo}?code=${code}`).then(async (res) => {
        if (res.code == 200) {
          const { convenienceServiceTypeList } = res.data;
          this.severTypeList = convenienceServiceTypeList;
          this.severTypeList.forEach(async (item) => {
            await this.getServiceTypeLv2(item.dictKey);
          });
          console.log(
            "this.severTypeList[this.currentIndex].dictKey----->",
            this.severTypeList[this.currentIndex].dictKey
          );

          this.requestData.serviceType =
            this.severTypeList[this.currentIndex].dictKey;
          this.serviceTypeLv1 = this.severTypeList[this.currentIndex].dictValue;
          // this.getServiceTypeLv2(this.requestData.serviceType);
          // this.getServiceList();
        }
      });
    },
    //最新发布
    goPublish() {
      this.$axios
        .get(`${getOwnServiceInfo}?userId=${this.userId}`)
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            if (data.status === 2) {
              const params = JSON.stringify(data);
              this.$toast({
                message: `${data.auditRemarks},请重新发布！`,
                duration: 3000,
              });
              setTimeout(() => {
                this.$router.push({
                  name: "convenientServicePublish",
                  query: {
                    params,
                  },
                });
              }, 2000);
            } else if (data.status === 0) {
              this.$router.push({
                name: "convenientServiceAudit",
              });
            } else {
              this.$router.push({
                name: "convenientServicePublish",
              });
            }
          }
        });
    },
    //获取列表数据
    getServiceList() {
      let params = {
        houseId: this.houseId || this.communityId,
        ...this.requestData,
      };
      this.$axios
        .get(
          `${getServiceList}`,
          { params },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            if (res.data.records.length === 0) {
              this.publishList = [];
              return;
            }
            this.publishList = res.data.records;
            this.publishList.forEach((item) => {
              if (item.coverPicture && item.coverPicture.length > 0) {
                item.coverPicture = this.$handleImg(
                  224,
                  220,
                  item.coverPicture
                );
              }
            });
          }
        });
    },
    //跳转到详情页面
    toDetailPage(item) {
      this.$router.push({
        name: "convenientServiceCategories",
        query: {
          serviceType: item.code,
          serviceTypeLv2: item.dictKey,
          currentIndex: this.currentIndex,
        },
      });
    },
    //服务类别跳转
    toPath(item) {
      this.$router.push({
        name: "convenientServiceAllCategories",
        query: {
          code: item.dictKey,
        },
      });
    },
    //跳转联系页面
    toPathPage(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped lang="less">
.convenientService {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 30px 0 30px;
  padding-bottom: calc(120px + constant(safe-area-inset-bottom));
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  .convenientService-banner {
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    background: #f7f7f7;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 0 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item-l {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    img {
      width: 16px;
      height: 28px;
    }
  }
  .section {
    width: 100%;
    flex: 1;
    max-height: calc(100% - 90px);
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .section-l {
      padding-top: 2px;
      width: 190px;
      overflow-y: scroll;
      height: 100%;
      background: #f7f7f7;
      border-radius: 0px 18px 16px 16px;
      margin-right: 20px;
      .nav {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        padding: 40px 20px 40px 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        position: relative;

        &.active {
          position: relative;
          color: #4864b0;
          background: #ffffff;
          &::before {
            content: "";
            width: 6px;
            height: 50%;
            background: #4864b0;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
      }
    }
    .section-r {
      position: relative;
      overflow-y: scroll;
      flex: 1;
      height: 100%;
      padding: 28px 0;
      box-sizing: border-box;
      max-width: calc(100% - 210px);
      &::-webkit-scrollbar {
        display: none;
      }

      .content {
        width: 100%;
        position: relative;

        .section-r-title {
          text-align: center;
          margin-bottom: 34px;
          box-sizing: border-box;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c2c2c2;
          line-height: 40px;
          display: flex;
          align-items: center;
          .txt {
            margin: 0 4px;
          }
          .line {
            height: 2px;
            background-image: linear-gradient(
              to right,
              #c2c2c2 0%,
              #c2c2c2 50%,
              transparent 0%
            );
            background-size: 16px 1px;
            background-repeat: repeat-x;
            flex: 1;
          }
        }
        .section-r-content {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;

          .item {
            display: block;
            text-align: center;
            height: 54px;
            line-height: 50px;
            box-sizing: border-box;
            width: calc((100% - 28px) / 3);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-shrink: 0;
            border-radius: 8px;
            border: 2px solid #3780ff;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3781ff;
            margin-bottom: 20px;
            &:not(:nth-child(3n)) {
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
  .publish-btn {
    z-index: 999;
    position: fixed;
    right: 0;
    bottom: 25%;
    width: 144px;
    height: 144px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .swiper {
    ::v-deep .van-swipe__indicators {
      bottom: 52px;
      .van-swipe__indicator {
        width: 12px;
        height: 12px;
        background: #e8e8e8;
        border-radius: 50%;
        &.van-swipe__indicator--active {
          width: 42px;
          height: 12px;
          background: #b5d1ff;
          border-radius: 6px;
        }
      }
    }
    .convenient-service-list {
      margin-top: 40px;
      margin-bottom: 104px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;

      .service-list-apply {
        // margin-top: 24px;
        // width: calc((100% - 184px) / 5);
        width: 20%;
        // margin-right: 38px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // &:nth-child(5n) {
        //   margin-right: 0;
        // }
        .icon {
          width: 100px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          text-align: center;
          margin-top: 10px;
          height: 34px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1c34;
          line-height: 34px;
        }
      }
    }
  }

  .convenient-publish-recently {
    width: 100%;
    box-sizing: border-box;
    .publish-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin-bottom: 40px;
      .publish-title-l {
        position: relative;
        margin-left: 18px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1a1c34;
        line-height: 50px;
        &::before {
          z-index: -1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          margin: auto;
          width: 120%;
          height: 16px;
          background: #e2e2e2;
          border-radius: 7px;
        }
      }
      .publish-title-r {
        display: flex;
        align-items: center;
        .text {
          height: 40px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3780ff;
          line-height: 40px;
          margin-right: 14px;
        }
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .publish-content {
      width: 100%;
      box-sizing: border-box;
      .content-detail {
        width: 100%;
        height: 220px;
        display: flex;
        align-items: stretch; //改变
        gap: 22px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        overflow: hidden;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .detail-l {
          width: 224px;
          height: 100%; //改变
          img {
            width: 100%;
            height: 100%;
          }
        }
        .detail-r {
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: calc(100% - 240px);
          box-sizing: border-box;
          padding-top: 24px;
          .detail-r-title {
            padding-right: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
          }
          .detail-r-describe {
            padding-right: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 8px 0 14px 0;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
          }
          .detail-r-evaluate {
            .detail-r-evaluate-status {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 110px;
              height: 38px;
              border-radius: 19px;
              border: 2px solid #ffd343;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffd343;
              line-height: 34px;
            }
            .rate {
              width: 206px;
              overflow: hidden;
              position: relative;
              height: 32px;
            }
          }
          .detail-r-serve-detail {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .time {
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #c2c2c2;
              line-height: 34px;
            }
            .btn {
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 194px;
              height: 62px;
              background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
              box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
              border-radius: 16px 0px 16px 0px;
              font-size: 26px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 62px;
            }
          }
        }
      }
    }
  }
}
</style>
